<template>
  <div v-if="event && race">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header :event="event"/>
      <!-- <RaceResultFilter :meta="meta" :event="event" :race="race" title="Manage race results" adminMode @change="updateResults"/> -->
      <v-card-text>
        Use this page to view results and disqualify invalid entries.
      </v-card-text>
      <RaceResultsGrid :event="event" :race="race" :results="results" :meta="meta" :filter="resultFilter" adminMode @refresh="loadData" @show-profile-details="showProfileDetails"/>
      <v-pagination 
        v-model="resultPage" 
        circle 
        :length="Math.ceil(meta.total_count / 50)" 
        @input="loadPage"
        class="py-4"
        /> 

      <ProfileDetailsDialog :eventId="event.id" :event="event" ref="profileDetailsDialog" />
    </v-card>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import eventService from "@/services/eventService";
import RaceResultFilter from "@/components/RaceResultFilter.vue";
import RaceResultsGrid from "@/components/RaceResultsGrid.vue";
import ProfileDetailsDialog from "@/components/ProfileDetailsDialog.vue";
import Header from './_Header.vue';

export default {
  name: "RaceResults",
  components: {
    RaceResultsGrid,
    RaceResultFilter,
    ProfileDetailsDialog,
    Header,
  },
  props: {
    tenant: Object,
    event: Object,
    race: Object,
  },
  data() {
    return {
      //event: null,
      //race: null,
      meta: null,
      resultPage: null,
      resultFilter: null,
      results: null,
      showPointInfo: false,
    };
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      var id = this.$route.params.id;
      var raceId = this.$route.params.raceId;
      const response = (await eventManagerService.getRaceResults(id, raceId)).data;
      this.meta = response.meta;
      this.results = response.data;
      console.log('/// results', this.meta);
    },
    
    async loadPage(page) {
      console.log('Loading page ', page, this.event, this.race);
      this.resultPage = page;
      //this.loadData();
      const response = (await eventManagerService.getRaceResults(this.event.id, this.race.id, this.resultPage)).data;
      this.results = response.data;
      this.meta = response.meta;
    },
    async updateResults(filter) {
      console.log('Setting admin search filter', filter);
      this.resultFilter = filter;
      this.loadData();
    },
    async showProfileDetails(result) {
      var id = result.id.startsWith('p_') ? result.id.substring(2) : result.id;
      this.$refs.profileDetailsDialog.open(id);
    }

  },
  computed: {
    breadcrumbItems() {
      return !this.event || !this.race ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id, raceId: this.race.id}} },
        { text: this.race.name, exact: true, to: { name: 'eventmanagerRace', params: {id: this.event.id, raceId: this.race.id}} },
        { text: 'Results', disabled: true },
      ];
    },
    hasFilter() {
      return this.resultFilter 
          && (this.resultFilter.search || this.resultFilter.gender || this.resultFilter.category);
    },    
  },
  watch: {
    race() {
      console.log('Switching to another race', this.race.id);
      this.loadData(); 
    }
  },

};
</script>
<style lang="scss">
</style>

